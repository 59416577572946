import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: '/android16'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    redirect: '/android16',
    children:[
      {
        path: '/home/puahAndroid13',
        name: '/home/puahAndroid13',
        component: () => import('../views/android13.vue')
      },

      // 13pro
      {
        path: '/android13',
        name: '/home/puahAndroid13',
        component: () => import('../views/android13.vue')
      },
      // android 14
      {
        path: '/home/puahAndroid14',
        name: '/home/puahAndroid14',
        component: () => import('../views/android14.vue')
      },
      {
        path: '/android14',
        name: '/home/puahAndroid14',
        component: () => import('../views/android14.vue')
      },
      {
        path: '/android15',
        name: '/home/puahAndroid15',
        component: () => import('../views/android15.vue')
      },
      {
        path: '/android16',
        name: 'Android16',
        component: () => import('../views/android16.vue')
      },
    ]
  },
  // 12pro
  {
    path: '/home/puahAndroid12',
    name: '/home/puahAndroid12',
    component: () => import('../views/android12.vue')
  },
  {
    path: '*',
    redirect: '/android16'
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
