<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  export default {
    created(){
      var link = document.querySelector("link[rel*='icon']");
      link.setAttribute('href', 'https://ota-cdn.lenovo.com/firmware/20235815521948-5210.ico');
    }
  }
</script>

<style lang="stylus">
boby,html{
  background: #E5E5E5;
  zoom: 0.75;
}
html {
  min-width: 940px;
  overflow-x: auto;
}
</style>
